<template>
   <div class="page_section login_page">
      <MobileTitle :title="'Passwort vergessen'"/>

      <div class="content_element timetable_element">
         <vue-form :state="formstate" @submit.prevent="forgetPassword" id="registerForm">
            <div class="timetable_wrapper">
               <div class="timetable_inner_wrapper">
                  <h5 class="timetable_title">Passwort vergessen</h5>
                  <div class="login_wrapper">
                     <validate tabindex="1">
                        <div class="login_email_wrapper">
                                     <span class="icon">
                                        <i class="ab-icon-user-account"></i>
                                    </span>
                           <InputText
                              name="email"
                              placeholder="E-Mail-Adresse"
                              inputId="registerEmail"
                              v-model="model.email"
                              type="email"
                              :required="true"
                              :disabled="false"
                           />
                        </div>
                        <field-messages name="email" class="vf-error_message">
                           <template slot="required" slot-scope="state">
                              <span
                                 v-if="state.$dirty || state.$submitted">{{'E-Mail-Adresse'}} is a required field</span>
                           </template>
                           <template slot="email" slot-scope="state">
                              <span v-if="!state.$valid && state.$dirty">Enter a valid Email</span>
                           </template>
                        </field-messages>
                     </validate>
                     <div class="register_links">
                        <a href="#" @click.prevent="$store.commit('globalPopup/setLoginOpen', true)">
                           Anmelden
                        </a>
                     </div>

                     <div class="timetable_submit_wrapper d-flex flex-column align-items-center">
                        <button class="btn btn-grey" :disabled="formSending">
                           <template v-if="formSending">{{ __t('send') }} ...</template>
                           <template v-else>{{ __t('send') }}</template>
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </vue-form>
      </div>

      <Footer/>
   </div>
</template>

<script>
   import {mapState} from 'vuex';
   import Footer from "../_common/Footer";
   import MobileTitle from "../_common/MobileTitle";
   import {InputText} from '../../components/_form';

   export default {
      name: "ForgotPassword",
      components: {
         Footer,
         InputText,
         MobileTitle
      },
      data() {
         return {
            model: [],
            formstate: {},
            formSending: false,
         }
      },
      computed: {
         ...mapState([
            'alert'
         ])
      },
      methods: {
         forgetPassword() {
            if (this.formstate.$invalid) {
               document.getElementsByClassName('vf-field-invalid')[0].focus();
               return;
            }
            this.formSending = true;
            this.$store.dispatch('authentication/restorePassword', {email: this.model.email});
         }
      },
      watch: {
         'alert.type': function (val) {
            this.formSending = false;
            if (val === 'alert-success') {
               this.model = [];
               this.formstate._reset();
            }
         }
      }
   }
</script>
